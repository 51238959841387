<template>
  <app-header></app-header>
  <div class="wrapper">
    <div class="contact-context">
      <h1 class="animate__animated animate__fadeInLeft animate__slow">
        Let's get in <span>touch</span>
      </h1>
      <p>Have a question, need help, or interested?</p>
      <hr />
    </div>
    <div class="pt-secondary" />
    <div class="contact-form">
      <div v-show="isAlert">
        <div v-if="isVerify" class="alert success">
          {{ alertMessage }}
        </div>
        <div v-else class="alert error">
          {{ alertMessage }}
        </div>
      </div>
      <form method="POST" data-netlify="true">
        <div class="form-group">
          <div class="col-25">
            <label for="name">Your Name</label>
          </div>
          <div class="col-75">
            <input
              type="text"
              name="name"
              v-model="name"
              placeholder="eg. John Doe"
              width="800px"
              required
            />
          </div>
        </div>
        <div class="form-group">
          <div class="col-25">
            <label for="email">Your Email</label>
          </div>
          <div class="col-75">
            <input
              type="email"
              v-model="email"
              placeholder="eg. example@mail.com"
              required
            />
          </div>
        </div>
        <div class="form-group">
          <div class="col-25">
            <label for="fname">Message</label>
          </div>
          <div class="col-75">
            <textarea
              rows="4"
              v-model="message"
              placeholder="Message"
              required
            ></textarea>
          </div>
        </div>
        <div class="form-group">
          <div class="col-25">
            <VueRecaptcha
              class="g-recaptcha"
              :sitekey="siteKey"
              :load-recaptcha-script="true"
              @verify="handleSuccess"
              @error="handleError"
            >
            </VueRecaptcha>
          </div>
        </div>
        <div class="form-group">
          <div class="col-25">
            <button type="submit">Send a message</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { VueRecaptcha } from "vue-recaptcha";
import { ref, computed, reactive, toRefs } from "vue";
export default {
  components: { VueRecaptcha },
  setup() {
    const isVerify = ref(false);
    const isAlert = ref(false);
    const alertMessage = ref("");
    const contact = reactive({
      name: "",
      email: "",
      message: "",
    });
    const siteKey = computed(() => {
      return process.env.VUE_APP_RECAPTCHA_SITE_KEY;
    });

    const handleError = () => {
      console.log("Recaptcha is InValid");
    };

    const handleSuccess = () => {
      isVerify.value = true;
      isAlert.value = false;
      console.log(isVerify.value);
    };
    const handleSubmit = () => {
      isAlert.value = true;
      alertMessage.value =
        isVerify.value === false
          ? "Please confirm you're not a robot."
          : "Thank you for message. It has been sent.";
      //"Your inqury was submitted and will be responded to as soon as possible. Thank you!"
      insertData(isVerify.value);
      clearField(isVerify.value);
    };
    const insertData = async (validate) => {
      if (validate) {
        try {
          await axios.post(`contact`, contact);
        } catch (error) {
          alertMessage.value = false;
          console.log(error);
        }
      }
    };
    const clearField = (clear) => {
      if (clear) {
        contact.name = null;
        contact.email = null;
        contact.message = null;
        setTimeout(function () {
          isAlert.value = false;
        }, 12000);
      }
    };

    return {
      handleSubmit,
      handleSuccess,
      handleError,
      clearField,
      insertData,
      siteKey,
      isVerify,
      isAlert,
      alertMessage,
      ...toRefs(contact),
    };
  },
};
</script>
<style scoped>
.contact-form {
  max-width: 600px;
  margin: auto;
}
.contact-context {
  padding: 0px 40px;
}
.contact-context h1 {
  font-size: 54px;
}
.contact-context h1 span {
  color: #5274ee;
  font-weight: bolder;
}
.contact-context p {
  padding-top: 8px;
  font-size: 16px;
  font-weight: 400;
  font-style: italic;
}
hr {
  margin-top: 40px;
  background-color: rgb(126, 125, 125);
  height: 1px;
  border: 0;
}
textarea:placeholder-shown,
input:placeholder-shown {
  font-style: italic;
}
input,
textarea {
  min-width: 460px;
  padding: 14px 10px;
  font-size: 17px;
}
textarea:focus,
input:focus {
  outline: none !important;
  border: 1px solid #5274ee;
  box-shadow: 0 0 2px #5e6f81;
}
label {
  font-weight: 600;
}
button {
  background-color: #5274ee;
  height: 45px;
  width: 100%;
  cursor: pointer;
}
.form-group {
  padding: 10px 0px;
}
.col-75 {
  padding-top: 5px;
}
.alert {
  border-radius: 5px;
  padding: 12px;
}
.error {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
@media only screen and (max-width: 400px) {
  .contact-form {
    max-width: 400px;
    margin: auto;
  }
  textarea,
  input {
    min-width: 260px;
    padding: 16px 10px;
  }
}

@media only screen and (min-width: 410px) and (max-width: 500px) {
  .contact-form {
    min-width: 315px;
    margin: auto;
  }
  textarea,
  input {
    min-width: 315px;
    padding: 16px 10px;
  }
}
</style>
