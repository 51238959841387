<template>
  <div class="wrapper">
    <div
      class="profile-avatar animate__animated animate__slideInDown animate__slow"
    >
      <img src="../../assets/images/avatar-modified.png" alt="icon" />
    </div>
    <div
      class="profile-info animate__animated animate__fadeInRight animate__slow"
    >
      <h1>{{ name }}</h1>
    </div>
    <div
      class="profile-position animate__animated animate__fadeInLeft animate__slow"
    >
      <h4>
        I'm a
        <span> <vue-typewriter-effect :strings="position" /></span>
      </h4>
    </div>
    <div
      class="profile-social animate__animated animate__fadeInUpBig animate__slow"
    >
      <ul>
        <li>
          <a href="https://www.instagram.com/vhinnn.dev/" target="_blank">
            <icon-instagram
          /></a>
        </li>
        <li>
          <a href="https://github.com/vhinvergara" target="_blank"
            ><icon-git
          /></a>
        </li>
        <li>
          <a href="https://unsplash.com/@vhinnn" target="_blank"
            ><icon-unsplash
          /></a>
        </li>
      </ul>
    </div>
    <div class="profile-grid">
      <div class="profile-grid-section">
        <div class="profile-grid-group">
          <h2>
            <vue3-autocounter
              :startAmount="0"
              :endAmount="others.projectCompleted"
              :duration="5"
              separator=","
              :autoinit="true"
              class="counter"
            />
          </h2>
          <p>Code Debugging {{ $store.state.isSample }}</p>
        </div>
        <div class="profile-grid-group">
          <h2>
            <vue3-autocounter
              :startAmount="0"
              :endAmount="others.yearWork"
              :duration="3"
              separator=","
              :autoinit="true"
              class="counter"
            />
          </h2>
          <p>years of <br />works</p>
        </div>
        <div class="profile-grid-group">
          <h2>
            <vue3-autocounter
              :startAmount="0"
              :endAmount="others.cupsCoffee"
              :duration="5"
              separator=","
              :autoinit="true"
              class="counter"
            />
          </h2>
          <p>Cups of <br />coffee</p>
        </div>
      </div>
    </div>

    <div class="profile-button-section">
      <button class="btn-download" @click="maintenance">
        Download CV
        <img
          style="position: relative; top: 3px; right: -6px"
          height="16"
          width="16"
          src="../../assets/images/download.png"
          alt="icon"
        />
      </button>

      <router-link to="/contact">
        <button class="btn-contact">Contact Me</button>
      </router-link>
    </div>
    <div
      class="separator-scroll animate__animated animate__shakeY animate__slower"
    >
      <img src="../../assets/images/scroll-down.png" alt="icon" />
    </div>
  </div>
</template>

<script>
import iconInstagram from "@/assets/images/svgs/iconInstagram.vue";
import iconGit from "@/assets/images/svgs/iconGit.vue";
import iconUnsplash from "@/assets/images/svgs/iconUnsplash.vue";
import { reactive, toRefs } from "@vue/reactivity";
export default {
  name: "SectionHome",
  components: {
    iconInstagram,
    iconGit,
    iconUnsplash,
  },
  setup() {
    const profile = reactive({
      name: "Alvin Vergara",
      position: ["Software Engineer", "Fullstack Developer", "Web Designer"],
      others: {
        yearWork: 8,
        projectCompleted: 32678,
        cupsCoffee: 13097,
      },
    });
    const maintenance = () => {
      window.open(
        "https://storage.googleapis.com/temp-inv-files/myresume.pdf",
        "_blank"
      );
    };
    const numberFormat = (value) => {
      return value.toLocaleString("en-US");
    };
    return {
      ...toRefs(profile),
      maintenance,
      numberFormat,
    };
  },
};
</script>

<style scoped>
.wrapper {
  padding: 120px 0px;
}
.profile-avatar,
.profile-info,
.profile-position {
  display: flex;
  justify-content: center;
}
.profile-info h1 {
  font-size: 40px;
  font-weight: 800;
}
.profile-position h4 span {
  font-size: 18px;
  font-weight: 600;
  padding-top: 8px;
  color: #5274ee;
}
.profile-avatar img {
  height: 198px;
  width: 200px;
  padding-bottom: 30px;
}
.profile-social {
  max-width: 120px;
  margin: auto;
  padding-top: 25px;
}
.profile-social ul {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 18px;
}
.profile-social ul li {
  display: flex;
  justify-content: center;
  align-content: center;
}
.profile-grid {
  padding-top: 20px;
  max-width: 400px;
  margin: auto;
}
.profile-grid-section {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 18px;
  grid-auto-rows: 100px;
}
.profile-grid-group {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
.profile-grid-group h2 {
  font-weight: 500;
  font-size: 24px;
  padding: 10px;
}
.profile-section {
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-download {
  background-color: #30d076;
  height: 48px;
  min-width: 160px;
  cursor: pointer;
}
.btn-contact {
  background-color: #5274ee;
  height: 48px;
  min-width: 150px;
  cursor: pointer;
}
.separator-scroll {
  padding: 50px 50px;
  max-width: 300px;
  margin: auto;
}
.profile-button-section {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 100px;
  justify-content: center;
  align-items: center;
  text-align: right;
  margin: auto;
}
.counter {
  color: #fff;
}
@media only screen and (max-width: 600px) {
  .profile-info h1 {
    font-size: 27px;
  }
  .profile-avatar img {
    height: 168px;
    width: 170px;
  }
  .profile-position h4 {
    font-size: 16px;
    padding-top: 8px;
  }
  .profile-social img {
    height: 25px;
    width: 25px;
  }
  .profile-grid-group h2 {
    font-size: 20px;
  }
  .separator-scroll {
    padding: 5px 50px;
    max-width: 300px;
    margin: auto;
  }
  .separator-scroll img {
    height: 90px;
    width: 90px;
  }
}
@media only screen and (min-width: 1366px) and (max-width: 1500px) {
  .profile-avatar img {
    height: 130px;
    width: 130px;
    padding-bottom: 20px;
  }
  .profile-info h1 {
    font-size: 27px;
    font-weight: 800;
  }
  .profile-position h4 {
    font-size: 15px;
    padding-top: 8px;
  }
  .profile-social {
    padding-top: 15px;
  }
  .profile-grid {
    padding-top: 4px;
  }
  .profile-grid-group h2 {
    font-size: 20px;
    padding: 10px;
  }
  .separator-scroll {
    padding: 2px 50px;
  }
  .separator-scroll img {
    height: 90px;
    width: 90px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1490px) {
  .profile-avatar img {
    height: 150px;
    width: 150px;
    padding-bottom: 25px;
  }
}

@media only screen and (min-width: 1500px) and (max-width: 1900px) {
  .profile-avatar img {
    height: 130px;
    width: 130px;
    padding-bottom: 20px;
  }
  .profile-info h1 {
    font-size: 27px;
    font-weight: 800;
  }
  .profile-position h4 {
    font-size: 15px;
    padding-top: 8px;
  }
  .profile-social {
    padding-top: 15px;
  }
  .profile-grid {
    padding-top: 4px;
  }
  .profile-grid-group h2 {
    font-size: 20px;
    padding: 10px;
  }
  .separator-scroll {
    padding: 2px 50px;
  }
  .separator-scroll img {
    height: 90px;
    width: 90px;
  }
}
</style>
