<template>
  <div class="pt-primary" />
  <div class="wrapper">
    <h1>About Me</h1>
    <div class="pt-secondary" />
    <div class="section-grid">
      <div class="responsive">
        <img
          src="../../assets/images/banner16.jpg"
          alt="icon"
          class="animate__animated animate__zoomIn animate__slow"
        />
      </div>
      <div class="responsive">
        <p class="animate__animated animate__fadeInRightBig animate__slow">
          Hello, I'm Vhin, a software and front-end developer based in Manila,
          Philippines, with 8 years of experience in building high-quality
          corporate projects as a software developer at an HMO Company.
          Additionally, I have 4 year of experience as a remote front-end
          developer in an IT Company. I graduated with a Bachelor of Science in
          Computer Science from La Consolacion University of the Philippines
          (LCUP). I am very passionate about improving my coding skills and
          solving problems. Currently, I am studying full-stack development
          using MEVN.
        </p>
        <p class="animate__animated animate__fadeInRightBig animate__slow">
          Aside from coding, I'm also an investor/trader who loves to buy
          technology stocks for mid-term to long-term investments.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
h1 {
  text-align: center;
}
.section-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.responsive {
  padding: 10px 20px;
}
.responsive img {
  width: 100%;
  height: 375px;
  border-radius: 8px;
}
p {
  line-height: 2.1rem;
  text-indent: 30px;
  text-align: left;
  font-size: 16px;
}

@media only screen and (max-width: 600px) {
  .section-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
  .responsive img {
    width: 100%;
    height: auto;
  }
  p {
    line-height: 35px;
    font-size: 14px;
  }
}
</style>
