<template>
  <router-view></router-view>
</template>
<script>
import { ref } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-core";
export default {
  name: "App",
  components: {},
  setup() {
    const isLoading = ref(false);

    onMounted(() => {
      setTimeout(function () {
        isLoading.value = true;
      }, 3000);
    });
    return {
      isLoading,
    };
  },
};
</script>
