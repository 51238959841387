<template>
  <div class="footer">
    <div class="wrapper">Copyright©2022</div>
  </div>
</template>

<script>
import { ref } from "vue";
export default {
  setup() {
    const year = ref(null);
    return {
      year,
    };
  },
};
</script>
<style scoped>
.footer {
  padding: 10px 0px;
  color: rgb(204, 198, 198);
  text-align: center;
  font-size: 11px;
}
</style>
