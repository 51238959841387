<template>
  <div class="pt-primary" />
  <div class="wrapper">
    <h1 style="text-align: center">My Portfolio</h1>
    <div class="pt-secondary" />
    <div class="section-grid">
      <div
        class="animate__animated animate__rollIn"
        v-for="(work, index) in works"
        :key="index"
        @click="isActive = index"
      >
        <div class="card">
          <img
            :class="{ opacity: index == isActive }"
            :src="require(`@/assets/images/works/${work.image}`)"
            alt="icon"
          />

          <div
            class="projects__modal animate__animated animate__fadeInUp"
            v-show="index == isActive"
          >
            <div>
              <span class="projects__subtitle">{{ work.type }}</span>
              <h3 class="projects__title">{{ work.name }}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="pt-primary" />
</template>

<script>
import { reactive, ref } from "vue";
export default {
  setup() {
    const isActive = ref();
    const works = reactive([
      {
        image: "work14.png",
        type: "Web",
        name: "Digital Application Portal - (DAP)",
      },
      {
        image: "work5.jpg",
        type: "Web",
        name: "ANEC Admin Portal Dashboard",
      },
      {
        image: "work6.jpg",
        type: "Software",
        name: "Loan Management System",
      },
      {
        image: "work11.jpg",
        type: "Web",
        name: "Personal Portfolio (Old Version)",
      },
      {
        image: "work12.png",
        type: "Software",
        name: "Posting Management System",
      },
      {
        image: "work13.png",
        type: "Software",
        name: "Members Availment Management System - (MAMS)",
      },
    ]);
    const alertModal = (x) => {
      alert(x);
    };

    return {
      works,
      isActive,
      alertModal,
    };
  },
};
</script>

<style scoped>
.section-grid {
  display: grid;
  gap: 20px;
}
.card img {
  width: 100%;
  height: 290px;
  border-radius: 10px;
}
.projects__modal {
  position: absolute;
  bottom: 20px;
  padding: 0px 20px;
  color: rgb(51, 50, 50);
  filter: drop-shadow(8px 8px 10px rgb(114, 116, 114));
}
.opacity {
  opacity: 0.3;
}
@media only screen and (max-width: 600px) {
  .section-grid {
    grid-template-columns: repeat(1, 1fr);
    padding: 0px 30px;
  }
  .card img {
    width: 100%;
    height: 220px;
    border-radius: 10px;
  }
}
/* Extra Small Devices, Phones */
@media only screen and (min-width: 480px) {
  .section-grid {
    grid-template-columns: repeat(1, 1fr);
    padding: 0px 50px;
  }
  .card img {
    width: 100%;
    height: 250px;
    border-radius: 10px;
  }
}

/* Small Devices, Tablets */
@media only screen and (min-width: 768px) {
  .section-grid {
    grid-template-columns: repeat(2, 1fr);
    padding: 0px 50px;
  }
  .card img {
    width: 100%;
    height: 280px;
    border-radius: 10px;
  }
}
</style>
