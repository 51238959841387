<template>
  <div class="wrapper">
    <div class="header-tabmenu">
      <button
        class="animate__animated"
        v-for="(value, index) in tabName"
        :key="index"
        @click="selectActiveTab(value, index)"
        :class="{ active: index === indexSelected }"
      >
        {{ value.name }}
      </button>
    </div>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import { computed, inject } from "vue";
export default {
  setup(props, { emit }) {
    const store = inject("$store");
    const isCurrentTab = ref(0);
    const tabName = ref([
      {
        id: "about",
        name: "About",
      },
      {
        id: "skills",
        name: "Skills",
      },
    ]);

    const selectActiveTab = (value, index) => {
      store.state.selectedComponentIndex = index;
      emit("passLoad", value);
    };
    const indexSelected = computed(() => {
      return store.state.selectedComponentIndex;
    });

    return {
      isCurrentTab,
      tabName,
      selectActiveTab,
      indexSelected,
    };
  },
};
</script>

<style scoped>
.header-tabmenu {
  margin: auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  background-color: #202122;
  height: 80px;
  border-radius: 10px;
}
button {
  margin: 10px;
  color: rgb(139, 137, 137);
  padding: 15px 32px;
  font-size: 18px;
  font-weight: 400;
  background-color: #202122;
  cursor: pointer;
  width: 140px;
}
.active {
  background-color: #111111;
  color: #fff;
}
</style>
