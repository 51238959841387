<template>
  <div class="wrapper">
    <nav>
      <ul
        class="sidebar animate__animated animate__fadeInRightBig"
        :class="{ expand: isMenuVisible }"
      >
        <li @click="isMenuVisible = !isMenuVisible">
          <a><icon-close /></a>
        </li>
        <li @click="isMenuVisible = false"><a href="/#home">Home</a></li>
        <li @click="aboutActionHandler()">
          <a href="/#about">About</a>
        </li>
        <li @click="skillsActionHandler()">
          <a href="/#skills">Skills</a>
        </li>
        <li @click="isMenuVisible = false">
          <a href="/#experience">Experience</a>
        </li>
        <li @click="isMenuVisible = false"><a href="/#portfolio">Work</a></li>
      </ul>
      <ul>
        <li>
          <a href="/">
            <img
              class="img-logo"
              src="../../assets/images/logo.png"
              alt="logo"
            />
          </a>
        </li>
        <li class="hideMobile"><a href="/#home">Home</a></li>
        <li class="hideMobile" @click="aboutActionHandler()">
          <a href="/#about">About</a>
        </li>
        <li class="hideMobile" @click="skillsActionHandler()">
          <a href="/#skills">Skills</a>
        </li>
        <li class="hideMobile"><a href="/#experience">Experience</a></li>
        <li class="hideMobile"><a href="/#portfolio">Work</a></li>
        <li class="menu-bar" @click="isMenuVisible = !isMenuVisible">
          <a><icon-menu /></a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import { inject, ref } from "vue";
import iconMenu from "@/assets/images/svgs/iconMenu.vue";
import iconClose from "@/assets/images/svgs/iconClose.vue";
export default {
  name: "HeroTopHeader",
  components: {
    iconMenu,
    iconClose,
  },
  setup() {
    const store = inject("$store");
    const isMenuVisible = ref(false);

    const aboutActionHandler = () => {
      store.state.selectedComponentIndex = 0;
      store.state.selectedComponentName = "About";
      store.state.selectedSectionID = "about";
      isMenuVisible.value = false;
    };
    const skillsActionHandler = () => {
      store.state.selectedComponentIndex = 1;
      store.state.selectedComponentName = "Skills";
      store.state.selectedSectionID = "skills";
      isMenuVisible.value = false;
    };

    return {
      aboutActionHandler,
      skillsActionHandler,
      isMenuVisible,
    };
  },
};
</script>
<style scoped>
.wrapper {
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: #111111;
}
nav {
  background-color: #111111;
}
nav ul {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
nav li {
  height: 50px;
  margin: 30px 0px;
}
nav a {
  height: 100%;
  padding: 0 30px;
  text-decoration: none;
  display: flex;
  align-items: center;
  color: #fff;
}
nav ul li a:hover {
  background-color: #202122;
  border-radius: 10px;
  color: #576deb;
  font-weight: 600;
}
nav li:first-child {
  margin-right: auto;
}
.sidebar {
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: 280px;
  z-index: 999;
  display: none;
  background-color: #202122;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.expand {
  display: flex;
}
.sidebar li {
  width: 100%;
}
.menu-bar {
  display: none;
}
.img-logo {
  height: 90px;
  width: 90px;
}
@media (max-width: 800px) {
  .hideMobile {
    display: none;
  }
  .menu-bar {
    display: block;
  }
  nav li {
    margin: 10px 0px;
  }
}
@media (max-width: 500px) {
  .wrapper {
    box-shadow: 5px 5px 10px rgba(70, 69, 69, 0.1);
  }
  .sidebar {
    width: 100%;
  }
  nav li {
    margin: 10px 0px;
  }
}
</style>
