<template>
  <div class="pt-primary" />
  <div class="wrapper">
    <h1 style="text-align: center">Experience</h1>
    <div class="pt-secondary" />
    <div class="timeline">
      <ul>
        <li class="animate__animated animate__fadeInLeftBig animate__slow">
          <div class="right_content">
            <span class="work-right">
              <img
                src="../../assets/images/accustack.png"
                alt="icon"
                height="50"
                width="50"
              />
              <h2>Accustack</h2>
            </span>
            <h4>Front-end Developer (Feb 2021)</h4>
            <p>
              As a shareholder and front-end developer, I bridge the worlds of
              business ownership and digital innovation. Sharing my expertise
              involves transforming design mockups and wireframes into
              functional user interfaces, ensuring consistency with design
              specifications.
            </p>
          </div>
          <div class="left_content">
            <h3>Present</h3>
          </div>
        </li>
        <li class="animate__animated animate__fadeInRightBig animate__slow">
          <div class="right_content">
            <span class="work-left">
              <img
                src="../../assets/images/iwc.png"
                alt="icon"
                height="50"
                width="50"
              />
              <h2>IMS Wellth Care, Inc</h2>
            </span>
            <h4>Programmer (Mar 2016)</h4>
            <p>
              Being a programmer for more than 8 years in this organization, I
              have accumulated a wealth of experience and knowledge that enables
              me to contribute effectively to our projects and teams. Solving
              problems and managing tasks efficiently is my main core, ensuring
              smooth project workflows and successful outcomes.
            </p>
          </div>
        </li>
        <li class="animate__animated animate__fadeInLeftBig animate__slow">
          <div class="right_content">
            <h2>Freelance</h2>
            <h4>Web Developer (Jun 2015 - Dec 31 2015)</h4>
            <p>
              After graduating, I'm trying to gain experience to become a
              freelance web developer. My first project involved creating an
              air-conditioning inventory system for my high school friends. The
              project lasted for over 3 months before it was finished. By the
              way, I used PHP and MySQL for the project.
            </p>
          </div>
          <div class="left_content">
            <h3>June 2015</h3>
          </div>
        </li>
        <li class="animate__animated animate__fadeInRightBig animate__slow">
          <div class="right_content">
            <span class="work-left">
              <img
                src="../../assets/images/ccp.png"
                alt="icon"
                height="50"
                width="50"
              />
              <h2>Cultural Center of the Philippines</h2>
            </span>
            <h4>Internship (Apr 2014 - Jun 2014)</h4>
            <p>
              As an intern, I learned a lot about real-world realities, from the
              basics to intermediate levels. Collaborating, managing tasks on
              time, and meeting deadlines are crucially important. Additionally,
              balancing paperwork collection with coding tasks is equally vital.
            </p>
          </div>
          <div class="left_content">
            <h3>March 2014</h3>
          </div>
        </li>
        <div style="clear: both"></div>
      </ul>
    </div>
  </div>
</template>

<script>
import { reactive } from "vue";
export default {
  setup() {
    const experience = reactive([
      {
        work: "Accustack",
        logo: "",
        position: "Front-end Developer",
        dateYear: "February 2021 - Present",
        description: "loremipsum",
      },
      {
        work: "IMS Wellth Care, Inc",
        logo: "",
        position: "Programmer",
        dateYear: "March 2016 - Present",
        description: "loremipsum",
      },
      {
        work: "Freelance",
        logo: "",
        position: "Web Developer",
        dateYear: "April 2015 - March 2016",
        description: "loremipsum",
      },
      {
        work: "Freelance",
        logo: "",
        position: "Web Developer",
        dateYear: "April 2015 - March 2016",
        description: "loremipsum",
      },
    ]);
    return {
      experience,
    };
  },
};
</script>

<style scoped>
.timeline:before {
  content: "";
  position: absolute;
  height: 100%;
  left: 50%;
  width: 2px;
  top: -20px;
  background: #fff;
  z-index: 1;
}
.timeline {
  position: relative;
  margin: 50px auto;
  width: 1000px;
  padding: 50px 0px 20px 0px;
}

.timeline ul {
  margin: 0;
  padding: 0;
}

.timeline ul li {
  list-style: none;
  box-sizing: border-box;
  line-height: normal;
  position: relative;
  width: 50%;
  padding: 40px 70px;
}
.work-right {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
}
.work-left {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
}
.timeline ul li .right_content h2 {
  color: rgb(255, 255, 255);
  padding: 0px 2px 8px 0px;
  text-align: center;
}
.timeline ul li .right_content h4 {
  /* color: rgb(166, 167, 168);*/
  color: rgba(59, 112, 239, 1);
  padding: 8px 2px 8px 0px;
  text-align: center;
}
.timeline ul li .right_content p {
  line-height: 24px;
  font-size: 15px;
  text-align: center;
  color: #c9c5c5;
}
.timeline ul li:nth-child(odd) {
  float: left;
  text-align: right;
  clear: both;
}

.timeline ul li:nth-child(even) {
  float: right;
  text-align: left;
  clear: both;
}

.left_content {
  padding-bottom: 20px;
}

.timeline ul li:nth-child(odd):before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  background: rgba(59, 112, 239, 1);
  border-radius: 50%;
  box-shadow: 0 0 0 3px rgba(58, 112, 239, 0.2);
  right: -6px;
  top: 24px;
  z-index: 1;
}

.timeline ul li:nth-child(even):before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  background: rgba(59, 112, 239, 1);
  border-radius: 50%;
  box-shadow: 0 0 0 3px rgba(58, 112, 239, 0.2);
  left: -6px;
  top: 24px;
}

.timeline ul li:nth-child(odd) .left_content {
  position: absolute;
  top: 12px;
  right: -165px;
  margin: 0;
  padding: 8px 16px;

  color: #fff;
  box-shadow: 0 0 0 3px rgba(59, 112, 239, 0.3);
  border-radius: 18px;
}

.timeline ul li:nth-child(even) .left_content {
  position: absolute;
  top: 12px;
  left: -165px;
  margin: 0;
  padding: 8px 16px;

  color: #fff;
  box-shadow: 0 0 0 3px rgba(59, 112, 239, 0.3);
  border-radius: 18px;
}

@media (max-width: 1000px) {
  .timeline {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .timeline {
    width: 100%;
  }
  .timeline:before {
    left: 20px;
  }
  .timeline ul li:nth-child(odd),
  .timeline ul li:nth-child(even) {
    width: 100%;
    text-align: left;
    padding-left: 50px;
  }

  .timeline ul li:nth-child(odd):before {
    top: -18px;
    left: 16px;
  }
  .timeline ul li:nth-child(even):before {
    top: -18px;
    left: 16px;
  }

  .timeline ul li:nth-child(odd) .left_content,
  .timeline ul li:nth-child(even) .left_content {
    top: -30px;
    left: 50px;
    right: inherit;
  }
}
</style>
