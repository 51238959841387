import { createStore } from "vuex";
export default createStore({
  state: {
    selectedComponentIndex: 0,
    selectedComponentName: "About",
    selectedSectionID: "about",
  },
  getters: {},
  mutations: {},
  actions: {},
  modules: {},
});
