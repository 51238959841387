import { createRouter, createWebHistory } from "vue-router";
import LandingPage from "../layout/LandingPage.vue";
import ContactPage from "../components/partials/SectionContactPage.vue";
const routes = [
  {
    path: "/",
    name: "home",
    component: LandingPage,
  },
  {
    path: "/contact",
    name: "contact",
    component: ContactPage,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
