<template>
  <app-header></app-header>
  <section id="home">
    <SectionHomePage />
  </section>

  <SectionTabPage @passLoad="getValue" />
  <section :id="$store.state.selectedSectionID">
    <keep-alive>
      <component :is="activeTab"></component>
    </keep-alive>
  </section>
  <section id="experience">
    <SectionExperiencePage />
  </section>
  <section id="portfolio">
    <SectionWorkPage />
  </section>

  <app-footer></app-footer>
</template>

<script>
import { computed, ref, inject } from "vue";
import SectionHomePage from "@/components/partials/SectionHomePage.vue";
import SectionTabPage from "@/components/partials/SectionTabPage.vue";
import SectionAboutPage from "@/components/partials/SectionAboutPage.vue";
import SectionSkillsPage from "@/components/partials/SectionSkillPage.vue";
import SectionWorkPage from "@/components/partials/SectionWorkPage.vue";
import SectionExperiencePage from "@/components/partials/SectionExperiencePage.vue";
export default {
  name: "LandingPage",
  components: {
    SectionHomePage,
    SectionTabPage,
    SectionAboutPage,
    SectionSkillsPage,
    SectionWorkPage,
    SectionExperiencePage,
  },
  setup() {
    const store = inject("$store");
    const isActive = ref("About");
    const selectedId = ref("");
    const indexSelected = ref(0);
    const getValue = (value) => {
      store.state.selectedComponentName = value.name;
      selectedId.value = value.id;
    };
    const activeTab = computed(() => {
      return `Section${store.state.selectedComponentName}Page`;
    });

    return {
      isActive,
      selectedId,
      activeTab,
      getValue,
      indexSelected,
    };
  },
};
</script>
