<template>
  <div class="pt-primary" />
  <div class="wrapper">
    <h1 style="text-align: center">Skills</h1>
    <div class="pt-secondary" />
    <div class="section-grid">
      <div
        class="animate__animated animate__rollIn"
        v-for="(skill, index) in skills"
        :key="index"
      >
        <div class="card">
          <div class="card-icons">
            <img
              :src="require(`@/assets/images/icons/${skill.icon}`)"
              alt="icon"
            />

            <div class="rating">
              <img
                src="../../assets/images/icons/star.png"
                alt="icon"
                v-for="index in skill.rating.star"
                :key="index"
              />
            </div>
          </div>
          <h3>{{ skill.language }}</h3>
          <p>
            {{ skill.description }}
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="pt-primary" />
</template>

<script>
import { reactive } from "vue";
export default {
  setup() {
    const skills = reactive([
      {
        language: "C#",
        icon: "csharp.png",
        rating: {
          star: 4,
        },
        description:
          "a most practical programming languages used for creating desktop applications.",
      },
      {
        language: "MySQL",
        icon: "mysql.png",
        rating: {
          star: 4,
        },
        description:
          "a open source database server provides for storing and manipulating data.",
      },
      {
        language: "HTML5",
        icon: "html.png",
        rating: {
          star: 5,
        },
        description:
          "it is used to define the text document within tag which defines the structure of web pages.",
      },
      {
        language: "CSS",
        icon: "css.png",
        rating: {
          star: 3,
        },
        description:
          "it is used for structuring, styling, and formatting web pages.",
      },
      {
        language: "Figma",
        icon: "figma.png",
        rating: {
          star: 3,
        },
        description:
          "a cloud-based design tool that is used to create and test apps and websites.",
      },
      {
        language: "Vue.js",
        icon: "vue.png",
        rating: {
          star: 4,
        },
        description:
          "creating the front end of web interfaces and one-page applications.",
      },
      {
        language: "JavaScript",
        icon: "javascript.png",
        rating: {
          star: 3,
        },
        description: "it is used to develop interactive web applications.",
      },
      {
        language: "Node",
        icon: "node.png",
        rating: {
          star: 3,
        },
        description:
          "building the back end of web applications, and creating gateways to call api.",
      },
      {
        language: "Git",
        icon: "git.png",
        rating: {
          star: 4,
        },
        description:
          "a version controlled system which you can use to maintain code and allows multiple users to work together.",
      },
      {
        language: "Heroku",
        icon: "heroku.png",
        rating: {
          star: 3,
        },
        description:
          "a cloud application platform that makes it easy to both develop and deploy web applications.",
      },
      {
        language: "Sequelize",
        icon: "sequelize.png",
        rating: {
          star: 3,
        },
        description:
          "an open-source Node.js module that enables JavaScript developers to work with relational databases more easily.",
      },
      {
        language: "Netlify",
        icon: "netlify.png",
        rating: {
          star: 3,
        },
        description:
          "a platform helps developers to build, test, and deploy websites",
      },
    ]);

    return {
      skills,
    };
  },
};
</script>

<style scoped>
.section-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  padding: 0px 35px;
}
.card {
  padding: 20px 20px;
  border-radius: 15px;
  min-height: 180px;
  background-color: #202122;
  box-shadow: 5px 5px 10px rgba(100, 100, 100, 0.1);
}
.card p {
  font-size: 15px;
  line-height: 21px;
  padding: 15px 0px;
  color: #c9c5c5;
}
.card-icons {
  padding: 10px 0px;
  padding-right: 5px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
}
.card-icons img {
  height: 48px;
  width: 48px;
}
.rating img {
  height: 24px;
  width: 24px;
}
.rating {
  display: flex;
  justify-content: flex-end;
}
@media only screen and (max-width: 600px) {
  .section-grid {
    grid-template-columns: repeat(1, 1fr);
  }
}
/* Extra Small Devices, Phones */
@media only screen and (min-width: 480px) {
  .section-grid {
    grid-template-columns: repeat(1, 1fr);
  }
}

/* Small Devices, Tablets */
@media only screen and (min-width: 768px) {
  .section-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* Medium Devices, Desktops */
@media only screen and (min-width: 992px) {
  .section-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}
</style>
