import axios from "axios";
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "../src/assets/css/global.css";
import LandingPage from "./layout/LandingPage.vue";
import LoadingPage from "./components/common/HeroLoading.vue";
import TopHeaderPage from "./components/common/HeroTopHeader.vue";
import FooterPage from "./components/common/HeroFooter.vue";
import VueTypewriterEffect from "vue-typewriter-effect";
import Vue3Autocounter from "vue3-autocounter";
import "animate.css";

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
const app = createApp(App);
app.use(store);
app.use(router);
app.component("app-landing", LandingPage);
app.component("app-loading", LoadingPage);
app.component("app-header", TopHeaderPage);
app.component("app-footer", FooterPage);
app.component("vue-typewriter-effect", VueTypewriterEffect);
app.component("vue3-autocounter", Vue3Autocounter);
app.provide("$store", store);
app.mount("#app");
